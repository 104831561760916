<template>
  <div class="uk-margin-top">
    <h4>Harga Kontrak Berlaku Sejak</h4>
    <div
      class="uk-child-width-auto@s uk-grid-small uk-grid uk-grid-stack"
      uk-grid
    >
      <div class="uk-width-1-3 uk-text-left">
        <label>Tanggal</label>
        <div
          class="uk-grid-small uk-margin-small-top"
          uk-grid
        >
          <div class="uk-inline">
            <span
              class="uk-form-icon uk-form-icon-flip"
              uk-icon
            >
              <img
                v-lazy="`${images}/icon/date.svg`"
                alt="icon upload"
                class="uk-form-icon uk-form-icon-flip icon-upload"
              >
            </span>
            <datepicker
              v-model="formData.effective_date"
              :disabled-dates="effectiveDisabled"
              class="uk-width-1-1"
              input-class="uk-input"
              format="dd/MM/yyyy"
              placeholder="DD/MM/YYYY"
            />  
          </div>
        </div>
      </div>
      <div class="uk-width-1-3 uk-text-left">
        <label>Tipe Harga</label>
        <div
          class="uk-grid-small uk-margin-small-top"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <multiselect
              v-model="priceTypeChoose"
              v-validate="'required'"
              name="cpt"
              label="name"
              track-by="value"
              :placeholder="contractPriceTypeList[0].name"
              :options="contractPriceTypeList"
              open-direction="bottom"
              :loading="isLoading"
              :close-on-select="true"
              :max-height="500"
              :show-no-result="true"
              @change="priceTypeChoose"
            />
          </div>
        </div>
      </div>
      <div class="uk-width-1-3 uk-text-left" />
    </div>
    
    
    

    <h4>Harga Kontrak Sapronak</h4>
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-3@l uk-width-1-3@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            Harga DOC/ekor
          </div>
          <div class="uk-width-1-1">
            <div class="uk-inline">
              <span
                class="uk-form-icon"
                uk-icon
              >Rp</span>
              <input
                v-model="formData.doc_price"
                class="uk-input"
                type="text"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-3@l uk-width-1-3@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            Harga Pakan/kg
          </div>
          <div class="uk-width-1-1">
            <div class="uk-inline">
              <span
                class="uk-form-icon"
                uk-icon
              >Rp</span>
              <input
                v-model="formData.feed_price"
                class="uk-input"
                type="text"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-3@l uk-width-1-3@xl">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            Harga OVK/pcs
          </div>
          <div class="uk-width-1-1">
            <div class="uk-inline">
              <span
                class="uk-form-icon"
                uk-icon
              >Rp</span>
              <input
                v-model="formData.ovk_price"
                class="uk-input"
                type="text"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <h4 style="margin-top: 48px">
      Harga Kontrak Jual Ayam Hidup
    </h4>
    <div class="uk-grid">
      <div class="uk-width-1-3">
        <div class="uk-width-1-1">
          Bobot Rataan (kg)
        </div>
      </div>
      <div class="uk-width-2-3">
        <div>Harga Jual Ayam Hidup/Kg</div>
      </div>
    </div>

    <div
      v-for="(doc, i) in formData.contract_price_chickens"
      :key="i"
      class="uk-grid uk-grid-small grid-top-margin"
    >
      <div class="uk-width-1-2 uk-width-1-2@s uk-width-1-2@m uk-width-1-3@l uk-width-1-3@xl">
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-1-2">
            <div class="uk-width-1-1">
              <input
                v-model="doc.min"
                class="uk-input"
                type="text"
              >
            </div>
          </div>
          <div class="centered">
            -
          </div>
          <div
            style="padding-left:0px;"
            class="uk-width-expand"
          >
            <div class="uk-width-1-1">
              <input
                v-model="doc.max"
                class="uk-input"
                type="text"
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-width-1-2 uk-width-1-2@s uk-width-1-2@m uk-width-2-3@l uk-width-2-3@xl"
        uk-grid
      >
        <div class="uk-width-3-4">
          <div class="uk-inline">
            <span
              class="uk-form-icon"
              uk-icon
            >Rp</span>
            <input
              v-model="doc.price"
              class="uk-input"
              type="text"
            >
          </div>
        </div>
        <div class="uk-width-1-4">
          <img
            v-lazy="`${images}/icon/trash.svg`"
            alt=""
            class="uk-margin-small-top img-click"
            @click="handleDelete(i)"
          >
        </div>
      </div>
    </div>
    <h2
      class="add-activity-line"
      @click="addNewPrice()"
    >
      <span>
        <img
          v-lazy="`${images}/icon/plus-activity.svg`"
          alt=""
          class="uk-margin-small-left"
        >
        Tambah Harga
      </span>
    </h2>
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin-top"
      uk-grid
    >
      <div class="uk-width-1-1 uk-text-right">
        <button
          class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
          type="button"
          @click="goToBack"
        >
          <img
            v-lazy="`${images}/icon/close.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Batal
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="showSaveConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE, CONTRACT_PRICE_TYPE_LIST } from '@/utils/constant'
import Datepicker from 'vuejs-datepicker'
import { 
  dateStringSimply, thousandSeparator, 
  thousandsSeparatorOninPut, 
  removeAlpabetic 
} from  '@/utils/formater'

export default {
  components: {
    Datepicker
  },
  props: {
    contractPrice: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      formData: {
        price_type: 0,
        contract_price_chickens: [{
          min: '',
          max: '',
          price: ''
        }]
      },
      maxCheck:true,
      maxLength:'',
      effectiveDisabled: {to: new Date(new Date().getTime() - 86400000)},
      contractPriceTypeList: CONTRACT_PRICE_TYPE_LIST.filter(item => item.name !== 'Semua'),
      priceTypeChoose: ''
    }
  },
  
  watch: {
    'formData.doc_price'(){
      this.formData.doc_price = thousandsSeparatorOninPut(this.formData.doc_price)
    },
    'formData.feed_price'(){
      this.formData.feed_price = thousandsSeparatorOninPut(this.formData.feed_price)
    },
    'formData.ovk_price'(){
      this.formData.ovk_price = thousandsSeparatorOninPut(this.formData.ovk_price)
    },
    'formData.contract_price_chickens': {
      deep: true,
      handler(newPrices) {
        for (let i = 0; i < newPrices.length; i++) {
          newPrices[i].min = thousandsSeparatorOninPut(this.preventAlphabetic(newPrices[i].min))
          newPrices[i].max = thousandsSeparatorOninPut(this.preventAlphabetic(newPrices[i].max))
          const prc = this.thousandsSeparatorOninPut(newPrices[i].price) 
          newPrices[i].price = prc != 0 ? prc.replace(',', '') : 0
        }
      }
    },
    async contractPrice() {
      this.formData = {
        effective_date: this.contractPrice.effective_date,
        doc_price : thousandSeparator(this.contractPrice.doc_price),
        feed_price: thousandSeparator(this.contractPrice.feed_price),
        ovk_price: thousandSeparator(this.contractPrice.ovk_price),
        contract_price_chickens: this.contractPrice.contract_price_chicken.map(doc => ({
          max: this.changeDot(String(doc.max.toFixed(3))),
          min: this.changeDot(String(doc.min.toFixed(3))),
          price: thousandSeparator(parseInt(doc.price))
        }))
      },
      this.priceTypeChoose =  this.contractPriceTypeList.filter(item => item.value == this.contractPrice.price_type.replace(/\./g, '').replace('Rp', ''))
    },
    priceTypeChoose(){
      this.priceTypeChoose ? this.formData.price_type = this.priceTypeChoose.value : this.formData.price_type
    }
  },
  methods: {
    thousandSeparator(s){
      return thousandSeparator(s)
    },
    thousandsSeparatorOninPut(s){
      return thousandsSeparatorOninPut(s)
    },
    preventAlphabetic(s){
      return removeAlpabetic(s)
    },
    handleDelete(i){
      this.formData.contract_price_chickens.splice(i, 1)
    },
    addNewPrice(){
      this.formData.contract_price_chickens.push({
        min: 0,
        max: 0,
        price: 0
      })
    },
    ...mapMutations({
      setModalEdit: 'contractPrice/SET_MODAL_EDIT'
    }),
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showMoreThan() {
      this.maxCheck = false
      console.log("testing")
    },
    changeComma(data) {
      data = data.replace(/,/g, '.')
      return data
    },
    changeDot(data) {
      data = data.replace(/\./g, ',')
      return data
    },
    showSaveConfirmModal() {
      this.formData.contract_price_chickens = this.formData.contract_price_chickens.map(doc => ({
        min: doc.min != 0 ? doc.min.replaceAll('.', '') : 0,
        max: doc.max !=0 ? doc.max.replaceAll('.', '') : 0,
        price: doc.price !=0 ? doc.price.replaceAll('.', '') : 0
      }))

      const payload = {
        effective_date: dateStringSimply(this.formData.effective_date).replaceAll('/', '-'),
        doc_price : Number(this.formData.doc_price.replaceAll('.', '') || 0),
        feed_price: Number(this.formData.feed_price.replaceAll('.', '') || 0),
        ovk_price: Number(this.formData.ovk_price.replaceAll('.', '') || 0),
        price_type: Number(this.formData.price_type) || 0,
        contract_price_chickens: this.formData.contract_price_chickens.map(doc => ({
          min: parseFloat(this.changeComma(doc.min)),
          max: parseFloat(this.changeComma(doc.max)),
          price: parseInt(doc.price)
        }))
      }

      window.UIkit.modal('#modal-save-confirm').show()
      this.setModalEdit(payload)
      // this.formData = {
      //   doc_price : this.contractPrice.doc_price,
      //   feed_price: this.contractPrice.feed_price,
      //   ovk_price: this.contractPrice.ovk_price,
      //   contract_price_chickens: this.contractPrice.contract_price_chicken.map(doc => ({
      //     max: this.changeDot(String(doc.max.toFixed(3))),
      //     min: this.changeDot(String(doc.min.toFixed(3))),
      //     price: parseInt(doc.price)
      //   }))
      // }
    }
  }
}
</script>

<style scoped>
h4 {
  font-weight: 500;
  font-size: 16px;
  color: #1F2E28;
  margin-bottom: 4px;
}
p {
  margin: 0 0 24px;
}

.centered {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  max-height: 40px;
  padding: 5px;
  margin: 12px 0 0;
  gap: 5px;
}
.grid-top-margin {
  margin-top: 4px;
}
.uk-form-icon {
    z-index: 1;
  }
.icon-upload {
  padding: 8px;
}
</style>
